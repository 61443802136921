<style lang="css">
.center-md {
  font-size: 30px;
  padding: 0.4rem;
}

.order-info-md {
  text-align: left;
}

.order-info-md > div {
  line-height: 0.6rem;
}

.order-code-md > .time {
  float: right;
}

.bankinfo-center {
  background-color: #FFFFFF;
  margin: 0.3rem 0;
  border-radius: 8px;
  border: 0px;
  -moz-box-shadow: 2px 1px 4px #d2d2d2;
  -webkit-box-shadow: 2px 1px 4px #d2d2d2;
  box-shadow: 2px 1px 4px #d2d2d2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444')";
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444');
  padding-bottom: 0.3rem;
}

.bankinfo-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.35rem;
  border-bottom: 1px solid #c4c4c4;
  text-align: left;
}

.copy-md {
  font-size: 0.20rem;
  color: #ffffff;
  margin-left: 0.18rem;
  padding: 0.1rem 0.25rem;
  text-align: center;
  font-weight: 500;
  background-color: #2a3dee;
  border-radius: 18px;
  text-decoration: none;
}

.orderCode-md {
  color: #000000;
  font-weight: 800;
}

.amount-md {
  font-weight: 800;
  color: #619919;
}

.payment-info-title {
  text-align: left;
}

.input-orderCode {
  position: relative;
  margin: 0.4rem 0;
}

.input-orderCode > label {
  position: absolute;
  top: 0.4rem;
  left: 0.25rem;
}

.inpu-orderCode {
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: right;
  padding: 0.4rem 0.2rem 0.4rem 3rem;
  font-size: 0.45rem;
  border: 0px;
  outline: none;
}

.sumbit-but {
  display: inline-block;
  width: 100%;
  font-size: 0.45rem;
  background-color: #2a3dee;
  padding: 0.25rem 0px;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  border-radius: 8px;
}

.select-img > div > img {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.swal2-content {
  font-size: 0.425rem !important;
}

.swal2-actions {
  flex-wrap: nowrap !important;
  margin: 0.45em auto 0;
  overflow: hidden;
}

.swal2-actions > button {
  width: 50%;
  font-size: 0.525rem !important;
  text-align: center;
  /*border-radius: 0 !important;*/
  border-color: #ffffff;
}

.swal2-content {
  padding: 0.55rem 0 0 0;
}

.swal2-actions > button:nth-child(1) {
}

.swal2-actions > button:nth-child(2) {
  color: #a7a7a7 !important;
}

.swal2-styled {
  padding: 0.625em 0em;
  margin: 0;
  background-color: white !important;
}

.swal2-popup {
  padding: 0;
  overflow: hidden;
}

.swal2-title {
  font-size: 0.5em !important;
}

.swal2-icon.swal2-warning {
  width: 1rem;
  height: 1rem;
  font-size: 0.2rem !important;
  font-weight: 800 !important;
}

#swal2-content {
  text-align: left;
  padding: 0 0.5rem;
}

.okbut {
  border: 0;
  width: 100% !important;
  margin: 0 0.45rem;
  border-radius: 1rem !important;
  color: #ffffff !important;
  margin-bottom: 0.3rem;
  background: -webkit-gradient(linear, 80 -82, -48 bottom, from(#f27005), to(#ededed)) !important; /*Safari */
  background: -moz-linear-gradient(top, #f27005, #ededed) !important; /*Firefox*/
}

.layui-layer-loading .layui-layer-content {
  width: 1.4rem !important;
  height: 0.6rem !important;
}

.layui-layer-hui .layui-layer-content {
  padding: 0.46rem 0.433333rem !important;
  text-align: center;
}

.layui-layer-dialog .layui-layer-content {
  font-size: 0.386667rem !important;
}

.copy-log {
  display: inline-block;
  margin: 0 0.16rem;
  width: 0.52rem;
  height: 0.52rem;
  vertical-align: top;
  background: url('~@/assets/images/copy-template.png');
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
}


@font-face {
  font-family: 'iconfont';
  src: url('~@/assets/font/iconfont.woff2?t=1657007420200') format('woff2'),
  url('~@/assets/font/iconfont.woff?t=1657007420200') format('woff'),
  url('~@/assets/font/iconfont.ttf?t=1657007420200') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 2.320rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.order-status-title {
  width: 100%;
  font-size: 0.55rem;
  padding: 0.52rem 0rem;
  text-align: center;
}

.lable-var-md{
  display: flex;
  align-items: center;
}
.national_flag{
  width: 1.5rem;
  height: 1rem;
}
.amount_title_mod {
  font-size: 1.00rem;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
}

.currency_mod {
  font-size: 0.50rem;
  margin-right: 0.15rem;
}
</style>

<template>
  <div class="center">
    <div class="center-md" v-for="(orderInfo,i) in queryObject" :key="i">
      <!-- 订单号 订单时间 -->
      <header class="order-info-md"  >
        <div class="order-status-title">payment result</div>
        <div class="order-code-md order-item">
          <span
              class="order-id">Order Id: {{
              orderInfo.payOrderId == undefined ? 'unknown' : orderInfo.payOrderId
            }}</span>
          <span class="copy-log" @click="copyFn(orderInfo.payOrderId)"></span>
        </div>
        <div class="order-time order-item">
          <span
              class="pay-order-time">Time: {{
              orderInfo.payOrderCreateAt == undefined ? 'unknown' : orderInfo.payOrderCreateAt
            }}</span>
        </div>
      </header>
      <div class="amount_title">
        <p class="amount_title_mod" v-if="orderInfo.currencySymbol">
          <span class="currency_mod">{{ orderInfo.currencySymbol }}</span>
          <span class="amount_mod">{{ (orderInfo.orderAmount / 100).toFixed(2) }}</span>
        </p>
      </div>
      <div class="bankinfo-center" v-for="(item,index) in orderInfo.bankCardOrderVos" :key="index">
        <div class="bankinfo-item">
          <label class="label-pl">UTR/Ref No</label>
          <div class="lable-var-md">
            <span class="orderCode-md" style="color: steelblue;">{{ item.bankOrderNo == undefined ? 'unknown' : item.bankOrderNo }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl">Bank card</label>
          <div class="lable-var-md">
            <span class="orderCode-md">{{ item.bankNo == undefined ? 'unknown' : item.bankNo }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl">Bank name</label>
          <div class="lable-var-md">
            <span class="orderCode-md">{{ item.bankName == undefined ? 'unknown' : item.bankName }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl">payment time</label>
          <div class="lable-var-md">
            <span class="orderCode-md">{{ item.createdAt == undefined ? 'unknown' : item.createdAt }}</span>
          </div>
        </div>
        <div class="bankinfo-item">
          <label class="label-pl" style="color: #a7a7a7;">Actual payment Amount</label>
          <div class="lable-var-md">
            <span class="amount-md">{{ orderInfo.currencySymbol }} {{ parseInt(item.amount) == 0  ? '- -' :( item.amount /100 ).toFixed(2) }}</span>
          </div>
        </div>
        <div class="paymentinfo-md">
          <!-- 付款中 -->
          <div class="iconfont" v-if="item.state == 0">&#xe600;</div>
          <!-- 付款成功 -->
          <div class="iconfont" v-else-if="item.state == 1">&#xe677;</div>
          <div class="iconfont" v-else>&#xe95e;</div>

          <!-- 付款中 -->
          <div class="orderCode-md" v-if="item.state == 0">Query Results</div>
          <!-- 付款成功 -->
          <div class="orderCode-md" v-else-if="item.state == 1">payment success</div>
          <div class="orderCode-md" v-else >unknown</div>
        </div>
      </div>
      </div>

  </div>
</template>

<script>

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
import {toRefs} from 'vue'
import {queryPayOrdersResultInfo} from '@/api/api'
import $ from 'jquery'
import config from "@/config";


export default {
  data() {
    return {
      orderinfoimg: null,
      queryObject: [],
      file: null,
      inputOrderId: null,
      orderId: null
    }
  },
  components: {},
  mounted() {
    const _this = this
    // 获取到订单号
    _this.orderId = this.$route.params.id
    // 请求后台获取 获取个卡订单
    queryPayOrdersResultInfo(_this.orderId).then(res => {
      if (res.length >=1){
        _this.queryObject = res
      } else {
        _this.$alert('', 'Data error', "error");
        $(".swal2-confirm").addClass("okbut")
        _this.$router.push({name: config.errorPageRouteName, params: {errInfo: 'Data error！'}})
      }
    }, function (err) {
      _this.$alert('', err.msg, "error");
      $(".swal2-confirm").addClass("okbut")
    })
    // this.$alert("", "订单交易信息上传成功！", "success");
    // $(".swal2-confirm").addClass("okbut")
  },
  methods: {
    copyFn(val) {
      // createElement() 方法通过指定名称创建一个元素
      var copyInput = document.createElement("input");
      //val是要复制的内容
      copyInput.setAttribute("value", val);
      document.body.appendChild(copyInput);
      copyInput.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyInput);
          this.$alert("", "复制成功", "success");
          $(".swal2-confirm").addClass("okbut")
        }
      } catch {
        console.log("复制失败，请检查浏览器兼容");
      }
    },
  },
  copyFn(val) {
    // createElement() 方法通过指定名称创建一个元素
    var copyInput = document.createElement("input");
    //val是要复制的内容
    copyInput.setAttribute("value", val);
    document.body.appendChild(copyInput);
    copyInput.select();
    try {
      var copyed = document.execCommand("copy");
      if (copyed) {
        document.body.removeChild(copyInput);
        this.$alert("", "复制成功", "success");
        $(".swal2-confirm").addClass("okbut")
        console.log("复制成功");
      }
    } catch {
      console.log("复制失败，请检查浏览器兼容");
    }
  },
  setup() {
    // eslint-disable-next-line no-undef
    const state = reactive({
      loading: false,
      pageInfo: {
        page: 1,
        rows: 10,
        total: 0,
      }
    })
    // 最后return 出去
    return {
      ...toRefs(state),
    }
  }
}
</script>

